/**
 * Comelit Cloud Services Authentication
 * Comelit Cloud Services Authentication
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ResponseBodyCcapiError {
  /**
   * Error code: 0 successful login 50 wrong login email or password 51 login email not yet activated -1 unknown error
   */
  code: number;
  /**
   * Error message
   */
  message: string;
}
