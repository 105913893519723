import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {
  CCSAuthenticationService,
  RequestBody,
  ResponseBody
} from '../../api/auth';
import {
  checkFormValidity,
  checkOpenParam,
  getCallback,
  getMode,
  isHca,
  isIngenium
} from '../../utils';
import { ApiService } from '../api.service';
import { ForgotPswModalComponent } from '../forgot-psw-modal/forgot-psw-modal.component';
import { SignupModalComponent } from '../signup-modal/signup-modal.component';
import { ActivatedRoute } from '@angular/router';

interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  mode: 'SSO' | 'TOKEN';
  loginGroup: FormGroup<LoginForm>;
  emailControl = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ]
  });
  passwordControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required]
  });
  isIngenium: boolean;
  image: SafeStyle;
  showPopups =
    environment.client === 'teletek' ||
    (environment.client === 'comelit' && !environment.production);

  client = environment.client;

  constructor(
    private authService: CCSAuthenticationService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private ts: TranslateService,
    private modalService: BsModalService,
    private sanitized: DomSanitizer
  ) {}

  goToLink: SafeStyle;

  ngOnInit(): void {
    this.mode = getMode();

    this.loginGroup = new FormGroup<LoginForm>({
      email: this.emailControl,
      password: this.passwordControl
    });

    this.image = `assets/images/${environment.client}-logo-main.svg`;

    this.goToLink = this.sanitized.bypassSecurityTrustHtml(
      this.ts.instant('SIGNIN.GO_TO_COMELIT_PRO')
    );

    this.isIngenium = isIngenium();

    if (isHca()) {
      this.showPopups = true;
      this.image = `assets/images/hca-logo.png`;
    }

    if (this.mode === 'SSO') {
      this.apiService.checkLogin().subscribe((login: any) => {
        if (login.loggedIn) {
          this.emailControl.setValue(login.loginEmail);
        }
      });
    }

    switch (checkOpenParam()) {
      case 'register':
        this.openRegisterModal();
        break;
      case 'psw_change':
        this.openRetrievePswModal();
        break;
    }
  }

  openRetrievePswModal() {
    const initialState: Partial<ForgotPswModalComponent> = {};

    const modalRef = this.modalService.show(ForgotPswModalComponent, {
      initialState,
      class: 'modal-md'
    });
  }

  openRegisterModal() {
    const initialState: Partial<SignupModalComponent> = {};

    const modalRef = this.modalService.show(SignupModalComponent, {
      initialState,
      class: 'modal-md'
    });
  }

  ssoLogin() {
    const callback = getCallback();
    this.apiService
      .loginSSo({
        loginEmail: this.emailControl.value,
        password: this.passwordControl.value,
        redirectUri: callback || document.location.href
      })
      .subscribe(
        (data: any) => {
          window.location.href = data.redirect;
        },
        (error) => {
          this.toastr.error(
            this.ts.instant('ERRORS.HTTP_ERROR'),
            this.ts.instant('ERRORS.LOGIN_ERROR')
          );
        }
      );
  }

  tokenLogin() {
    const requestBody: RequestBody = {
      ccapi: {
        version: '1.1.0',
        endpoint: {
          version: '1.0.0',
          service: 'eps/rtk',
          requuid: this.apiService.getRandomNumber(0, 4294967296).toString()
        },
        login: {
          provider: 'NATIVE',
          providerData: {
            id: this.emailControl.value,
            password: this.passwordControl.value
          }
        }
      }
    };

    this.authService.rtk(requestBody).subscribe(
      (v: ResponseBody) => {
        if (v.ccapi.error.code === 0) {
          const callback = getCallback();
          window.location.href = `${callback || environment.sitePath}${
            environment.client === 'teletek' ? '/' : ''
          }/?token=${v.ccapi.body.token}`;
        } else {
          this.toastr.error(
            this.ts.instant('ERRORS.HTTP_ERROR'),
            this.ts.instant('ERRORS.LOGIN_ERROR')
          );
        }
      },
      () => {
        this.toastr.error(
          this.ts.instant('ERRORS.HTTP_ERROR'),
          this.ts.instant('ERRORS.LOGIN_ERROR')
        );
      }
    );
  }

  login() {
    if (checkFormValidity(this.loginGroup)) {
      this.mode === 'TOKEN' ? this.tokenLogin() : this.ssoLogin();
    }
  }
}
