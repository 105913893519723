/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SetPropertyDtoString } from './setPropertyDtoString';
import { SetPropertyDtoIsoLanguage6391 } from './setPropertyDtoIsoLanguage6391';
import { SetPropertyDtoIsoCountry31661 } from './setPropertyDtoIsoCountry31661';
import { SetPropertyDtoUserTypeEnum } from './setPropertyDtoUserTypeEnum';
import { SetPropertyDtoLocalDate } from './setPropertyDtoLocalDate';
import { SetPropertyDtoBoolean } from './setPropertyDtoBoolean';
import { SetPropertyDtoPreferredContactMethodEnum } from './setPropertyDtoPreferredContactMethodEnum';

/**
 * SetMyProfileRequestDto: \'language\' and \'country\' are deprecated in favour of \'isoLanguageCode\' and \'isoCountryCode\'
 */
export interface SetMyProfileRequestDto {
  isoCountryCode?: SetPropertyDtoIsoCountry31661;
  isoLanguageCode?: SetPropertyDtoIsoLanguage6391;
  address?: SetPropertyDtoString;
  businessName?: SetPropertyDtoString;
  cellnum?: SetPropertyDtoString;
  city?: SetPropertyDtoString;
  company?: SetPropertyDtoString;
  country?: SetPropertyDtoString;
  dateOfBirth?: SetPropertyDtoLocalDate;
  distributor?: SetPropertyDtoString;
  ecommerceUserId?: SetPropertyDtoString;
  contactEmail?: SetPropertyDtoString;
  firstName?: SetPropertyDtoString;
  lastName?: SetPropertyDtoString;
  language?: SetPropertyDtoString;
  profilePictureBase64?: SetPropertyDtoString;
  region?: SetPropertyDtoString;
  state?: SetPropertyDtoString;
  streetNumber?: SetPropertyDtoString;
  vatNumber?: SetPropertyDtoString;
  zipCode?: SetPropertyDtoString;
  phoneNumber?: SetPropertyDtoString;
  userType?: SetPropertyDtoUserTypeEnum;
  crmUserId?: SetPropertyDtoString;
  receiveNewsletter?: SetPropertyDtoBoolean;
  preferredContactMethod?: SetPropertyDtoPreferredContactMethodEnum;
  navigationLanguage?: SetPropertyDtoString;
}
