/**
 * Comelit Cloud Services Authentication
 * Comelit Cloud Services Authentication
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * This object in the response is filled with values only on error code 0
 */
export interface ResponseBodyCcapiBody {
  /**
   * Seconds of validiy
   */
  ttltoken: number;
  /**
   * CCS token credentials
   */
  token: string;
  /**
   * Instant of expiry in seconds from the UNIX Epoch
   */
  expirySeconds: string;
}
