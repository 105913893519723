<footer class="footer wrapper">
  <div class="container">
    <div *ngIf="client === 'comelit'" class="row justify-content-between">
      <div class="col-lg-7 order-1 order-lg-1">
        <span class="d-inline">
          <strong class="company">{{ 'FOOTER.COMELIT' | translate }}</strong>

          <br />
          {{ 'FOOTER.ADDRESS' | translate }}
          <br />
          {{ 'FOOTER.PHONE1' | translate }}
          <a id="comelit-phone" [href]="'FOOTER.PHONE2' | translate">
            {{ 'FOOTER.PHONE3' | translate }}
          </a>
          -
          <span [innerHTML]="moreInfo"></span>

          <br />
          {{ 'FOOTER.MORE_INFO2' | translate }}
          <br />
          {{ 'FOOTER.COPYRIGHT' | translate }} -
          {{ 'FOOTER.RIGHTS' | translate }}
          <a
            *ngIf="!isHca"
            id="comelit-credits"
            href="https://www.welcomedigital.it"
            target="_blank"
            class="credits-link"
          >
            {{ 'FOOTER.CREDITS' | translate }}
          </a>
          -
          <a
            id="comelit-privacy"
            target="_blank"
            [href]="'FOOTER.PRIVACY_LINK' | translate"
          >
            {{ 'FOOTER.PRIVACY' | translate }}
          </a>
        </span>
      </div>

      <div class="col-lg-2 order-2 order-lg-2 mt-3 mt-lg-0">
        <div class="links-container">
          <a
            id="comelit-email"
            [href]="'mailto:' + ('FOOTER.EMAIL' | translate)"
            target="_blank"
          >
            <strong>{{ 'FOOTER.EMAIL' | translate }}</strong>
          </a>

          <br />

          <a
            id="comelit-site"
            [href]="'https://' + ('FOOTER.SITE' | translate)"
            target="_blank"
          >
            <strong>{{ 'FOOTER.SITE' | translate }}</strong>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="client === 'teletek'" class="row justify-content-between">
      <div class="col-lg-7 order-1 order-lg-1">
        <span class="d-inline">
          <strong class="company">{{ 'FOOTER.TELETEK' | translate }}</strong>

          <br />
          {{ 'FOOTER.TELETEK_ADDRESS' | translate }}
          <br />
          {{ 'FOOTER.PHONE1' | translate }}
          <a id="teletek-phone" [href]="'FOOTER.TELETEK_PHONE2' | translate">
            {{ 'FOOTER.TELETEK_PHONE3' | translate }}
          </a>
          {{ 'FOOTER.TELETEK_FAX' | translate }}
        </span>
      </div>

      <div class="col-lg-2 order-2 order-lg-2 mt-3 mt-lg-0">
        <div class="links-container">
          <a
            id="teletek-site"
            [href]="'https://' + ('FOOTER.TELETEK_SITE' | translate)"
            target="_blank"
          >
            <strong>{{ 'FOOTER.TELETEK_SITE' | translate }}</strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
