<div class="modal-body">
  <p>
    {{
      client === 'comelit'
        ? ('SIGNUP.CAPTION' | translate)
        : ('SIGNUP.CAPTION_TELETEK' | translate)
    }}
  </p>

  <h2 class="primary-color">{{ 'SIGNUP.TITLE' | translate }}</h2>

  <form class="form-default" [formGroup]="signupGroup">
    <fieldset>
      <div class="form-group">
        <label>
          {{ 'SIGNUP.EMAIL' | translate }}
          <sup>*</sup>
        </label>

        <input
          id="email-register"
          name="email"
          [placeholder]="'SIGNUP.EMAIL' | translate"
          formControlName="loginEmail"
          type="email"
          class="custom-input"
        />

        <app-control-errors
          id="email-register-validator"
          controlName="loginEmail"
          [customMessages]="{
            pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
          }"
        ></app-control-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'SIGNUP.PASSWORD' | translate }}
          <sup>*</sup>
        </label>

        <input
          id="password-register"
          name="password"
          [placeholder]="'SIGNUP.PASSWORD' | translate"
          formControlName="password"
          type="password"
          class="custom-input"
        />

        <app-control-errors
          id="password-register-validator"
          controlName="password"
          [customMessages]="{
            pattern: 'VALIDATE.PASSWORD_PATTERN' | translate
          }"
        ></app-control-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'SIGNUP.CONFIRM_PASSWORD' | translate }}
          <sup>*</sup>
        </label>

        <input
          id="password-confirm"
          name="password"
          [placeholder]="'SIGNUP.CONFIRM_PASSWORD' | translate"
          formControlName="passwordConfirm"
          type="password"
          class="custom-input"
        />

        <app-control-errors
          id="password-confirm-validator"
          controlName="passwordConfirm"
          [customMessages]="{
            required: 'VALIDATE.PASSWORD_REQUIRED' | translate,
            mustMatch: 'VALIDATE.PASSWORDS_NOT_EQUAL' | translate
          }"
        ></app-control-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'SIGNUP.USER_TYPE' | translate }}
          <sup>*</sup>
        </label>

        <select
          id="user-type"
          name="user-type"
          formControlName="userType"
          class="custom-input"
        >
          <option disabled="disabled" value="">
            {{ 'SIGNUP.USER_TYPE_SELECT' | translate }}
          </option>

          <option *ngFor="let item of roles" [value]="item.value">
            {{ item.label }}
          </option>
        </select>

        <app-control-errors
          id="user-type-validator"
          controlName="userType"
        ></app-control-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'SIGNUP.COUNTRY' | translate }}
          <sup>*</sup>
        </label>

        <select
          id="country"
          name="country"
          formControlName="isoCountryCode"
          class="custom-input"
        >
          <option value="">{{ 'SIGNUP.COUNTRY_SELECT' | translate }}</option>

          <option *ngFor="let country of countryList" [value]="country.value">
            {{ country.label }}
          </option>
        </select>

        <app-control-errors
          id="country-validator"
          controlName="isoCountryCode"
        ></app-control-errors>
      </div>

      <div class="form-group">
        <label class="text-label">
          {{ 'SIGNUP.COMMERCIAL_INFO' | translate }}
        </label>

        <label class="d-inline" for="commercial-yes">
          <input
            [formControl]="commercialControl"
            type="radio"
            name="commercial"
            id="commercial-yes"
            [value]="true"
          />
          {{ 'SIGNUP.YES' | translate }}
        </label>

        <label class="ml-3 d-inline" for="commercial-no">
          <input
            [formControl]="commercialControl"
            type="radio"
            name="commercial"
            id="commercial-no"
            [value]="false"
          />
          {{ 'SIGNUP.NO' | translate }}
        </label>
      </div>

      <div class="form-group">
        <label class="text-label">
          <input
            [formControl]="privacyControl"
            type="checkbox"
            name="privacy"
            id="privacy"
          />
          {{ 'SIGNUP.PRIVACY' | translate }}
          <a
            id="register-privacy"
            target="_blank"
            class="link-primary"
            [href]="
              client === 'teletek'
                ? 'https://teletek-electronics.com/%d0%bf%d0%be%d0%bb%d0%b8%d1%82%d0%b8%d0%ba%d0%b0-%d0%b7%d0%b0-%d0%bf%d0%be%d0%b2%d0%b5%d1%80%d0%b8%d1%82%d0%b5%d0%bb%d1%81%d0%bd%d0%be%d1%82/'
                : ('FOOTER.PRIVACY_LINK' | translate)
            "
          >
            {{ 'SIGNUP.PRIVACY_LINK' | translate }}
          </a>
        </label>

        <app-control-errors
          id="privacy-validator"
          controlName="privacy"
        ></app-control-errors>
      </div>
    </fieldset>

    <div class="text-center">
      <button
        id="signup"
        (click)="signup()"
        type="submit"
        class="button-primary px-5 pt-2 pb-1 mt-3"
      >
        {{ 'SIGNUP.SIGNUP' | translate }}
      </button>

      <br />
      <br />

      <a
        href="javascript:void(0)"
        id="close"
        class="link-primary"
        (click)="modalRef.hide()"
      >
        {{ 'SIGNUP.HAVE_AN_ACCOUNT' | translate }}
      </a>
    </div>
  </form>
</div>
