/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SetPropertyDtoIsoCountry31661 {
  value?: SetPropertyDtoIsoCountry31661.ValueEnum;
  unset?: boolean;
}
export namespace SetPropertyDtoIsoCountry31661 {
  export type ValueEnum =
    | 'AF'
    | 'AX'
    | 'AL'
    | 'DZ'
    | 'AS'
    | 'AD'
    | 'AO'
    | 'AI'
    | 'AQ'
    | 'AG'
    | 'AR'
    | 'AM'
    | 'AW'
    | 'AU'
    | 'AT'
    | 'AZ'
    | 'BS'
    | 'BH'
    | 'BD'
    | 'BB'
    | 'BY'
    | 'BE'
    | 'BZ'
    | 'BJ'
    | 'BM'
    | 'BT'
    | 'BO'
    | 'BQ'
    | 'BA'
    | 'BW'
    | 'BV'
    | 'BR'
    | 'IO'
    | 'BN'
    | 'BG'
    | 'BF'
    | 'BI'
    | 'KH'
    | 'CM'
    | 'CA'
    | 'CV'
    | 'KY'
    | 'CF'
    | 'TD'
    | 'CL'
    | 'CN'
    | 'CX'
    | 'CC'
    | 'CO'
    | 'KM'
    | 'CG'
    | 'CD'
    | 'CK'
    | 'CR'
    | 'CI'
    | 'HR'
    | 'CU'
    | 'CW'
    | 'CY'
    | 'CZ'
    | 'DK'
    | 'DJ'
    | 'DM'
    | 'DO'
    | 'EC'
    | 'EG'
    | 'SV'
    | 'GQ'
    | 'ER'
    | 'EE'
    | 'ET'
    | 'FK'
    | 'FO'
    | 'FJ'
    | 'FI'
    | 'FR'
    | 'GF'
    | 'PF'
    | 'TF'
    | 'GA'
    | 'GM'
    | 'GE'
    | 'DE'
    | 'GH'
    | 'GI'
    | 'GR'
    | 'GL'
    | 'GD'
    | 'GP'
    | 'GU'
    | 'GT'
    | 'GG'
    | 'GN'
    | 'GW'
    | 'GY'
    | 'HT'
    | 'HM'
    | 'VA'
    | 'HN'
    | 'HK'
    | 'HU'
    | 'IS'
    | 'IN'
    | 'ID'
    | 'IR'
    | 'IQ'
    | 'IE'
    | 'IM'
    | 'IL'
    | 'IT'
    | 'JM'
    | 'JP'
    | 'JE'
    | 'JO'
    | 'KZ'
    | 'KE'
    | 'KI'
    | 'KP'
    | 'KR'
    | 'KW'
    | 'KG'
    | 'LA'
    | 'LV'
    | 'LB'
    | 'LS'
    | 'LR'
    | 'LY'
    | 'LI'
    | 'LT'
    | 'LU'
    | 'MO'
    | 'MK'
    | 'MG'
    | 'MW'
    | 'MY'
    | 'MV'
    | 'ML'
    | 'MT'
    | 'MH'
    | 'MQ'
    | 'MR'
    | 'MU'
    | 'YT'
    | 'MX'
    | 'FM'
    | 'MD'
    | 'MC'
    | 'MN'
    | 'ME'
    | 'MS'
    | 'MA'
    | 'MZ'
    | 'MM'
    | 'NA'
    | 'NR'
    | 'NP'
    | 'NL'
    | 'NC'
    | 'NZ'
    | 'NI'
    | 'NE'
    | 'NG'
    | 'NU'
    | 'NF'
    | 'MP'
    | 'NO'
    | 'OM'
    | 'PK'
    | 'PW'
    | 'PS'
    | 'PA'
    | 'PG'
    | 'PY'
    | 'PE'
    | 'PH'
    | 'PN'
    | 'PL'
    | 'PT'
    | 'PR'
    | 'QA'
    | 'RE'
    | 'RO'
    | 'RU'
    | 'RW'
    | 'BL'
    | 'SH'
    | 'KN'
    | 'LC'
    | 'MF'
    | 'PM'
    | 'VC'
    | 'WS'
    | 'SM'
    | 'ST'
    | 'SA'
    | 'SN'
    | 'RS'
    | 'SC'
    | 'SL'
    | 'SG'
    | 'SX'
    | 'SK'
    | 'SI'
    | 'SB'
    | 'SO'
    | 'ZA'
    | 'GS'
    | 'SS'
    | 'ES'
    | 'LK'
    | 'SD'
    | 'SR'
    | 'SJ'
    | 'SZ'
    | 'SE'
    | 'CH'
    | 'SY'
    | 'TW'
    | 'TJ'
    | 'TZ'
    | 'TH'
    | 'TL'
    | 'TG'
    | 'TK'
    | 'TO'
    | 'TT'
    | 'TN'
    | 'TR'
    | 'TM'
    | 'TC'
    | 'TV'
    | 'UG'
    | 'UA'
    | 'AE'
    | 'GB'
    | 'US'
    | 'UM'
    | 'UY'
    | 'UZ'
    | 'VU'
    | 'VE'
    | 'VN'
    | 'VG'
    | 'VI'
    | 'WF'
    | 'EH'
    | 'YE'
    | 'ZM'
    | 'ZW';
  export const ValueEnum = {
    Af: 'AF' as ValueEnum,
    Ax: 'AX' as ValueEnum,
    Al: 'AL' as ValueEnum,
    Dz: 'DZ' as ValueEnum,
    As: 'AS' as ValueEnum,
    Ad: 'AD' as ValueEnum,
    Ao: 'AO' as ValueEnum,
    Ai: 'AI' as ValueEnum,
    Aq: 'AQ' as ValueEnum,
    Ag: 'AG' as ValueEnum,
    Ar: 'AR' as ValueEnum,
    Am: 'AM' as ValueEnum,
    Aw: 'AW' as ValueEnum,
    Au: 'AU' as ValueEnum,
    At: 'AT' as ValueEnum,
    Az: 'AZ' as ValueEnum,
    Bs: 'BS' as ValueEnum,
    Bh: 'BH' as ValueEnum,
    Bd: 'BD' as ValueEnum,
    Bb: 'BB' as ValueEnum,
    By: 'BY' as ValueEnum,
    Be: 'BE' as ValueEnum,
    Bz: 'BZ' as ValueEnum,
    Bj: 'BJ' as ValueEnum,
    Bm: 'BM' as ValueEnum,
    Bt: 'BT' as ValueEnum,
    Bo: 'BO' as ValueEnum,
    Bq: 'BQ' as ValueEnum,
    Ba: 'BA' as ValueEnum,
    Bw: 'BW' as ValueEnum,
    Bv: 'BV' as ValueEnum,
    Br: 'BR' as ValueEnum,
    Io: 'IO' as ValueEnum,
    Bn: 'BN' as ValueEnum,
    Bg: 'BG' as ValueEnum,
    Bf: 'BF' as ValueEnum,
    Bi: 'BI' as ValueEnum,
    Kh: 'KH' as ValueEnum,
    Cm: 'CM' as ValueEnum,
    Ca: 'CA' as ValueEnum,
    Cv: 'CV' as ValueEnum,
    Ky: 'KY' as ValueEnum,
    Cf: 'CF' as ValueEnum,
    Td: 'TD' as ValueEnum,
    Cl: 'CL' as ValueEnum,
    Cn: 'CN' as ValueEnum,
    Cx: 'CX' as ValueEnum,
    Cc: 'CC' as ValueEnum,
    Co: 'CO' as ValueEnum,
    Km: 'KM' as ValueEnum,
    Cg: 'CG' as ValueEnum,
    Cd: 'CD' as ValueEnum,
    Ck: 'CK' as ValueEnum,
    Cr: 'CR' as ValueEnum,
    Ci: 'CI' as ValueEnum,
    Hr: 'HR' as ValueEnum,
    Cu: 'CU' as ValueEnum,
    Cw: 'CW' as ValueEnum,
    Cy: 'CY' as ValueEnum,
    Cz: 'CZ' as ValueEnum,
    Dk: 'DK' as ValueEnum,
    Dj: 'DJ' as ValueEnum,
    Dm: 'DM' as ValueEnum,
    Do: 'DO' as ValueEnum,
    Ec: 'EC' as ValueEnum,
    Eg: 'EG' as ValueEnum,
    Sv: 'SV' as ValueEnum,
    Gq: 'GQ' as ValueEnum,
    Er: 'ER' as ValueEnum,
    Ee: 'EE' as ValueEnum,
    Et: 'ET' as ValueEnum,
    Fk: 'FK' as ValueEnum,
    Fo: 'FO' as ValueEnum,
    Fj: 'FJ' as ValueEnum,
    Fi: 'FI' as ValueEnum,
    Fr: 'FR' as ValueEnum,
    Gf: 'GF' as ValueEnum,
    Pf: 'PF' as ValueEnum,
    Tf: 'TF' as ValueEnum,
    Ga: 'GA' as ValueEnum,
    Gm: 'GM' as ValueEnum,
    Ge: 'GE' as ValueEnum,
    De: 'DE' as ValueEnum,
    Gh: 'GH' as ValueEnum,
    Gi: 'GI' as ValueEnum,
    Gr: 'GR' as ValueEnum,
    Gl: 'GL' as ValueEnum,
    Gd: 'GD' as ValueEnum,
    Gp: 'GP' as ValueEnum,
    Gu: 'GU' as ValueEnum,
    Gt: 'GT' as ValueEnum,
    Gg: 'GG' as ValueEnum,
    Gn: 'GN' as ValueEnum,
    Gw: 'GW' as ValueEnum,
    Gy: 'GY' as ValueEnum,
    Ht: 'HT' as ValueEnum,
    Hm: 'HM' as ValueEnum,
    Va: 'VA' as ValueEnum,
    Hn: 'HN' as ValueEnum,
    Hk: 'HK' as ValueEnum,
    Hu: 'HU' as ValueEnum,
    Is: 'IS' as ValueEnum,
    In: 'IN' as ValueEnum,
    Id: 'ID' as ValueEnum,
    Ir: 'IR' as ValueEnum,
    Iq: 'IQ' as ValueEnum,
    Ie: 'IE' as ValueEnum,
    Im: 'IM' as ValueEnum,
    Il: 'IL' as ValueEnum,
    It: 'IT' as ValueEnum,
    Jm: 'JM' as ValueEnum,
    Jp: 'JP' as ValueEnum,
    Je: 'JE' as ValueEnum,
    Jo: 'JO' as ValueEnum,
    Kz: 'KZ' as ValueEnum,
    Ke: 'KE' as ValueEnum,
    Ki: 'KI' as ValueEnum,
    Kp: 'KP' as ValueEnum,
    Kr: 'KR' as ValueEnum,
    Kw: 'KW' as ValueEnum,
    Kg: 'KG' as ValueEnum,
    La: 'LA' as ValueEnum,
    Lv: 'LV' as ValueEnum,
    Lb: 'LB' as ValueEnum,
    Ls: 'LS' as ValueEnum,
    Lr: 'LR' as ValueEnum,
    Ly: 'LY' as ValueEnum,
    Li: 'LI' as ValueEnum,
    Lt: 'LT' as ValueEnum,
    Lu: 'LU' as ValueEnum,
    Mo: 'MO' as ValueEnum,
    Mk: 'MK' as ValueEnum,
    Mg: 'MG' as ValueEnum,
    Mw: 'MW' as ValueEnum,
    My: 'MY' as ValueEnum,
    Mv: 'MV' as ValueEnum,
    Ml: 'ML' as ValueEnum,
    Mt: 'MT' as ValueEnum,
    Mh: 'MH' as ValueEnum,
    Mq: 'MQ' as ValueEnum,
    Mr: 'MR' as ValueEnum,
    Mu: 'MU' as ValueEnum,
    Yt: 'YT' as ValueEnum,
    Mx: 'MX' as ValueEnum,
    Fm: 'FM' as ValueEnum,
    Md: 'MD' as ValueEnum,
    Mc: 'MC' as ValueEnum,
    Mn: 'MN' as ValueEnum,
    Me: 'ME' as ValueEnum,
    Ms: 'MS' as ValueEnum,
    Ma: 'MA' as ValueEnum,
    Mz: 'MZ' as ValueEnum,
    Mm: 'MM' as ValueEnum,
    Na: 'NA' as ValueEnum,
    Nr: 'NR' as ValueEnum,
    Np: 'NP' as ValueEnum,
    Nl: 'NL' as ValueEnum,
    Nc: 'NC' as ValueEnum,
    Nz: 'NZ' as ValueEnum,
    Ni: 'NI' as ValueEnum,
    Ne: 'NE' as ValueEnum,
    Ng: 'NG' as ValueEnum,
    Nu: 'NU' as ValueEnum,
    Nf: 'NF' as ValueEnum,
    Mp: 'MP' as ValueEnum,
    No: 'NO' as ValueEnum,
    Om: 'OM' as ValueEnum,
    Pk: 'PK' as ValueEnum,
    Pw: 'PW' as ValueEnum,
    Ps: 'PS' as ValueEnum,
    Pa: 'PA' as ValueEnum,
    Pg: 'PG' as ValueEnum,
    Py: 'PY' as ValueEnum,
    Pe: 'PE' as ValueEnum,
    Ph: 'PH' as ValueEnum,
    Pn: 'PN' as ValueEnum,
    Pl: 'PL' as ValueEnum,
    Pt: 'PT' as ValueEnum,
    Pr: 'PR' as ValueEnum,
    Qa: 'QA' as ValueEnum,
    Re: 'RE' as ValueEnum,
    Ro: 'RO' as ValueEnum,
    Ru: 'RU' as ValueEnum,
    Rw: 'RW' as ValueEnum,
    Bl: 'BL' as ValueEnum,
    Sh: 'SH' as ValueEnum,
    Kn: 'KN' as ValueEnum,
    Lc: 'LC' as ValueEnum,
    Mf: 'MF' as ValueEnum,
    Pm: 'PM' as ValueEnum,
    Vc: 'VC' as ValueEnum,
    Ws: 'WS' as ValueEnum,
    Sm: 'SM' as ValueEnum,
    St: 'ST' as ValueEnum,
    Sa: 'SA' as ValueEnum,
    Sn: 'SN' as ValueEnum,
    Rs: 'RS' as ValueEnum,
    Sc: 'SC' as ValueEnum,
    Sl: 'SL' as ValueEnum,
    Sg: 'SG' as ValueEnum,
    Sx: 'SX' as ValueEnum,
    Sk: 'SK' as ValueEnum,
    Si: 'SI' as ValueEnum,
    Sb: 'SB' as ValueEnum,
    So: 'SO' as ValueEnum,
    Za: 'ZA' as ValueEnum,
    Gs: 'GS' as ValueEnum,
    Ss: 'SS' as ValueEnum,
    Es: 'ES' as ValueEnum,
    Lk: 'LK' as ValueEnum,
    Sd: 'SD' as ValueEnum,
    Sr: 'SR' as ValueEnum,
    Sj: 'SJ' as ValueEnum,
    Sz: 'SZ' as ValueEnum,
    Se: 'SE' as ValueEnum,
    Ch: 'CH' as ValueEnum,
    Sy: 'SY' as ValueEnum,
    Tw: 'TW' as ValueEnum,
    Tj: 'TJ' as ValueEnum,
    Tz: 'TZ' as ValueEnum,
    Th: 'TH' as ValueEnum,
    Tl: 'TL' as ValueEnum,
    Tg: 'TG' as ValueEnum,
    Tk: 'TK' as ValueEnum,
    To: 'TO' as ValueEnum,
    Tt: 'TT' as ValueEnum,
    Tn: 'TN' as ValueEnum,
    Tr: 'TR' as ValueEnum,
    Tm: 'TM' as ValueEnum,
    Tc: 'TC' as ValueEnum,
    Tv: 'TV' as ValueEnum,
    Ug: 'UG' as ValueEnum,
    Ua: 'UA' as ValueEnum,
    Ae: 'AE' as ValueEnum,
    Gb: 'GB' as ValueEnum,
    Us: 'US' as ValueEnum,
    Um: 'UM' as ValueEnum,
    Uy: 'UY' as ValueEnum,
    Uz: 'UZ' as ValueEnum,
    Vu: 'VU' as ValueEnum,
    Ve: 'VE' as ValueEnum,
    Vn: 'VN' as ValueEnum,
    Vg: 'VG' as ValueEnum,
    Vi: 'VI' as ValueEnum,
    Wf: 'WF' as ValueEnum,
    Eh: 'EH' as ValueEnum,
    Ye: 'YE' as ValueEnum,
    Zm: 'ZM' as ValueEnum,
    Zw: 'ZW' as ValueEnum
  };
}
