import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CCSAuthenticationService } from 'src/app/api/auth';
import { checkFormValidity, getCallback } from 'src/app/utils';
import { ApiService } from '../api.service';
import { SignupModalComponent } from '../signup-modal/signup-modal.component';
interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'app-residorg-login-form',
  templateUrl: './residorg-login-form.component.html',
  styleUrls: ['./residorg-login-form.component.scss']
})
export class ResidorgLoginFormComponent implements OnInit {
  loginGroup: FormGroup<LoginForm>;
  emailControl = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ]
  });
  passwordControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required]
  });

  constructor(
    private authService: CCSAuthenticationService,
    private toastr: ToastrService,
    private ts: TranslateService,
    private modalService: BsModalService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.loginGroup = new FormGroup<LoginForm>({
      email: this.emailControl,
      password: this.passwordControl
    });

    this.apiService.checkLogin().subscribe((login: any) => {
      if (login.loggedIn) {
        this.emailControl.setValue(login.loginEmail);
      }
    });
  }

  openRegisterModal() {
    const initialState: Partial<SignupModalComponent> = {};

    const modalRef = this.modalService.show(SignupModalComponent, {
      initialState,
      class: 'modal-md'
    });
  }

  goToRegistration() {
    const callback = getCallback();
    window.location.href = `https://usvc-preprod.cloud.comelitgroup.com/common-pages/residorg/?redirect_uri=${callback}`;
  }

  login() {
    if (checkFormValidity(this.loginGroup)) {
      const callback = getCallback();
      this.apiService
        .loginSSo({
          loginEmail: this.emailControl.value,
          password: this.passwordControl.value,
          redirectUri: callback || document.location.href
        })
        .subscribe(
          (data: any) => {
            window.location.href = data.redirect;
          },
          (error) => {
            this.toastr.error(
              this.ts.instant('ERRORS.HTTP_ERROR'),
              this.ts.instant('ERRORS.LOGIN_ERROR')
            );
          }
        );
    }
  }
}
