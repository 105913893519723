<div class="card-body">
  <h2 class="primary-color my-3">{{ 'SIGNIN.TITLE' | translate }}</h2>
  <p>
    Per accedere al portale è necessario utilizzare un'utenza
    <span class="primary-color">Comelit</span>, se non si ha un'utenza Comelit
    si prega di
    <a (click)="goToRegistration()" class="link-primary">registrarsi</a>
  </p>
  <div class="modal-message"></div>
  <form class="form-default" [formGroup]="loginGroup">
    <fieldset>
      <div class="form-group">
        <label>
          {{ 'SIGNIN.EMAIL' | translate }}
          <sup>*</sup></label
        >
        <input
          id="email"
          name="email"
          [placeholder]="'SIGNIN.EMAIL' | translate"
          formControlName="email"
          type="email"
          class="custom-input"
        />
        <app-control-errors
          id="email-validator"
          controlName="email"
          [customMessages]="{
            pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
          }"
        ></app-control-errors>
      </div>
      <div class="form-group">
        <label class="">
          {{ 'SIGNIN.PASSWORD' | translate }}
          <sup>*</sup></label
        >
        <input
          id="password"
          name="password"
          [placeholder]="'SIGNIN.PASSWORD' | translate"
          formControlName="password"
          type="password"
          class="custom-input"
        />
        <app-control-errors
          id="password-validator"
          controlName="password"
        ></app-control-errors>
      </div>
    </fieldset>
    <div class="text-center">
      <button
        id="submit"
        (click)="login()"
        type="submit"
        class="button-primary px-5 pt-2 pb-1 my-3"
      >
        {{ 'SIGNIN.LOGIN' | translate }}
      </button>
    </div>
  </form>
</div>
