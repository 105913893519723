import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public defaultHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {}

  changePsw(body: any) {
    let responseType: 'text' | 'json' = 'json';
    let headers = this.defaultHeaders;
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post(
      `${environment.basePath}/servicerest/user/endPasswordResetting`,
      {
        ccapi: {
          version: '1.1.0',
          endpoint: {
            service: 'user/endPasswordResetting',
            endpoint: '1.0.0'
          },
          login: {},
          body: body
        }
      },
      {
        responseType: <any>responseType,
        headers: headers
      }
    );
  }

  resetPsw(body: any) {
    let responseType: 'text' | 'json' = 'json';
    let headers = this.defaultHeaders;
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post(
      `${environment.basePath}/servicerest/user/resetPassword`,
      {
        ccapi: {
          version: '1.1.0',
          endpoint: {
            service: 'user/resetPassword',
            endpoint: '1.0.0'
          },
          login: {},
          body: body
        }
      },
      {
        responseType: <any>responseType,
        headers: headers
      }
    );
  }

  checkLogin() {
    let responseType: 'text' | 'json' = 'json';
    let headers = this.defaultHeaders;
    headers = headers.set('Content-Type', 'application/json');

    return this.http.get(
      `${environment.residorgBasePath}/servicerest/singlesignon/check-login`,
      {
        responseType: <any>responseType,
        headers: headers
      }
    );
  }

  getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  register(body: any) {
    let responseType: 'text' | 'json' = 'json';
    let headers = this.defaultHeaders;
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post(
      `${environment.basePath}/servicerest/user/register`,
      {
        ccapi: {
          version: '1.1.0',
          endpoint: {
            requuid: this.getRandomNumber(0, 2147483648).toString(),
            service: 'user/register',
            version: '1.0.0'
          },
          login: {},
          body: body
        }
      },
      {
        responseType: <any>responseType,
        headers: headers
      }
    );
  }

  loginSSo(body: any) {
    let responseType: 'text' | 'json' = 'json';

    let headers = this.defaultHeaders;
    headers = headers.set('Content-Type', 'application/json');

    return this.http.post(
      `${environment.residorgBasePath}/servicerest/singlesignon/login`,
      body,
      {
        responseType: <any>responseType,
        headers: headers
      }
    );
  }
}
