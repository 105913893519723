import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginFormComponent } from './shared/login-form/login-form.component';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { NewPasswordComponent } from './shared/new-password/new-password.component';
import { environment } from 'src/environments/environment';
import { ResidorgLoginFormComponent } from './shared/residorg-login-form/residorg-login-form.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component:
          environment.client === 'residorg'
            ? ResidorgLoginFormComponent
            : LoginFormComponent,
        data: {
          title: _('NAVIGATION.LOGIN')
        }
      },
      {
        path: 'new-password',
        component: NewPasswordComponent,
        data: {
          title: _('NAVIGATION.NEW_PASSWORD')
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
