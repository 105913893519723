/**
 * Comelit Cloud Services Authentication
 * Comelit Cloud Services Authentication
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestBodyCcapiLoginProviderData } from "./requestBodyCcapiLoginProviderData";

export interface RequestBodyCcapiLogin {
  /**
   * Only possible value: NATIVE
   */
  provider: RequestBodyCcapiLogin.ProviderEnum;
  providerData: RequestBodyCcapiLoginProviderData;
}
export namespace RequestBodyCcapiLogin {
  export type ProviderEnum = "NATIVE";
  export const ProviderEnum = {
    Native: "NATIVE" as ProviderEnum,
  };
}
