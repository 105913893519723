/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SetPropertyDtoUserTypeEnum {
  value?: SetPropertyDtoUserTypeEnum.ValueEnum;
  unset?: boolean;
}
export namespace SetPropertyDtoUserTypeEnum {
  export type ValueEnum =
    | 'INSTALLER'
    | 'DISTRIBUTOR'
    | 'DESIGNER'
    | 'BUILDING_MANAGER'
    | 'EXCELLENCE_PARTNER'
    | 'END_USER'
    | 'ARCHITECT'
    | 'SYSTEM_INTEGRATOR';
  export const ValueEnum = {
    Installer: 'INSTALLER' as ValueEnum,
    Distributor: 'DISTRIBUTOR' as ValueEnum,
    Designer: 'DESIGNER' as ValueEnum,
    BuildingManager: 'BUILDING_MANAGER' as ValueEnum,
    ExcellencePartner: 'EXCELLENCE_PARTNER' as ValueEnum,
    EndUser: 'END_USER' as ValueEnum,
    Architect: 'ARCHITECT' as ValueEnum,
    SystemIntegrator: 'SYSTEM_INTEGRATOR' as ValueEnum
  };
}
