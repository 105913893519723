<div class="modal-body">
  <p>{{ 'PSW.CAPTION' | translate }}</p>
  <h2 class="primary-color">{{ 'PSW.TITLE' | translate }}</h2>
  <form class="form-default" [formGroup]="pswGroup">
    <fieldset>
      <div class="form-group">
        <label>
          {{ 'PSW.EMAIL' | translate }}
          <sup>*</sup></label
        >
        <input
          id="email-psw"
          name="email"
          [placeholder]="'PSW.EMAIL' | translate"
          formControlName="email"
          type="email"
          class="custom-input"
        />
        <app-control-errors
          id="email-psw-validator"
          controlName="email"
          [customMessages]="{
            pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
          }"
        ></app-control-errors>
      </div>
    </fieldset>
    <div class="text-center">
      <button
        id="send"
        (click)="resetPsw()"
        type="submit"
        class="button-primary px-5 pt-2 pb-1 mt-3"
      >
        {{ 'PSW.SEND' | translate }}
      </button>
    </div>
  </form>
</div>
