/**
 * Comelit Cloud Services Authentication
 * Comelit Cloud Services Authentication
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RequestBodyCcapiLoginProviderData {
  /**
   * The user\'s login email
   */
  id: string;
  /**
   * The password
   */
  password: string;
}
