import { TranslateService } from "@ngx-translate/core";

function translateInit(ts: TranslateService): Promise<void> {
  let lang = getUserLanguage();
  const enPromise = import(`../assets/i18n/${lang}.json`).then(
    (res) => {
      ts.setTranslation(lang, res);
      ts.setDefaultLang(lang);
    },
    () => {
      lang = "en";
    }
  );

  if (lang === "en") {
    return enPromise.then(() => {
      ts.use("en");
    });
  } else {
    return enPromise.then(() =>
      import(`../assets/i18n/${lang}.json`).then(
        (res) => {
          ts.setTranslation(lang, res);
          ts.use(lang);
        },
        () => {
          ts.use("en");
        }
      )
    );
  }
}

export function appInit(ts: TranslateService): () => Promise<void> {
  return () => translateInit(ts);
}

export function getUserLanguage() {
  return (
    localStorage.getItem("language") ||
    (navigator.language ? navigator.language.split("-")[0] : "en")
  );
}
