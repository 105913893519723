/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ResetPasswordResponseDto } from './resetPasswordResponseDto';

export interface BasePortalApiResponseDtoResetPasswordResponseDto {
  code?: number;
  message?: string;
  label?: string;
  data?: ResetPasswordResponseDto;
}
