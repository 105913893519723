import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

export function markFormGroupTouched(formGroup: UntypedFormGroup) {
  Object.values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

export function checkFormValidity(form: UntypedFormGroup): boolean {
  if (form.invalid) {
    markFormGroupTouched(form);
    setTimeout(() => {
      let firstInvalidEl = (document.querySelector(
        'input.ng-invalid.ng-touched'
      ) ||
        document.querySelector(
          'ng-select.ng-invalid.ng-touched'
        )) as HTMLElement | null;

      if (firstInvalidEl) {
        firstInvalidEl.scrollIntoView({ block: 'center' });
        window.scrollBy(0, -100);
        firstInvalidEl.focus();
      }
    });
    return false;
  } else {
    return true;
  }
}

export let callback: string;
export let openParam: any;
export let mode: 'SSO' | 'TOKEN';

export function setQueryParams(val: any) {
  mode = val.mode || 'TOKEN';
  openParam = val.open;
  callback = val.callback || val.redirect_uri;
}

export function getMode() {
  return mode;
}
export function checkOpenParam() {
  return openParam;
}
export function getCallback() {
  return callback;
}

export function isHca() {
  const cb = getCallback();
  return cb === environment.pacLink && environment.client === 'comelit';
}

export function isIngenium() {
  const cb = getCallback();
  return cb === environment.ingeniumLink && environment.client === 'comelit';
}
