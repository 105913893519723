/**
 * Comelit Cloud Services Authentication
 * Comelit Cloud Services Authentication
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestBodyCcapiEndpoint } from "./requestBodyCcapiEndpoint";
import { RequestBodyCcapiLogin } from "./requestBodyCcapiLogin";

export interface RequestBodyCcapi {
  /**
   * Only possible value: 1.1.0
   */
  version: RequestBodyCcapi.VersionEnum;
  endpoint: RequestBodyCcapiEndpoint;
  login: RequestBodyCcapiLogin;
}
export namespace RequestBodyCcapi {
  export type VersionEnum = "1.1.0";
  export const VersionEnum = {
    _110: "1.1.0" as VersionEnum,
  };
}
