<header id="page-header" class="header d-flex">
  <div class="container">
    <div class="row">
      <nav id="main-nav" class="navbar navbar-expand-lg">
        <div class="navbar-brand-container">
          <div class="navbar-brand header-logo">
            <img
              [src]="image"
              [ngClass]="client"
              [alt]="getAlt(client)"
              [title]="getAlt(client)"
              class="logo"
            />
          </div>
        </div>
        <div class="right align-items-end pr-3" style="align-self: auto">
          <ng-select
            id="language"
            class="language-selector"
            [items]="languages"
            bindLabel="name"
            bindValue="value"
            [formControl]="languageControl"
            [searchable]="false"
            [clearable]="false"
          >
            <ng-template ng-label-tmp let-item="item">
              <img height="15" width="15" [src]="item.image" />
              <span class="custom-option">{{ item.name }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <span [id]="item.value">
                <img height="15" width="15" [src]="item.image" />
                <span class="custom-option">{{ item.name }}</span>
              </span>
            </ng-template>
          </ng-select>
        </div>
      </nav>
    </div>
  </div>
</header>
