import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AUTH_BASE_PATH, ApiModule as AuthApiModule } from './api/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInit } from './app.init';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { ControlErrorsComponent } from './shared/control-errors/control-errors.component';
import { ForgotPswModalComponent } from './shared/forgot-psw-modal/forgot-psw-modal.component';
import { LoginFormComponent } from './shared/login-form/login-form.component';
import { SignupModalComponent } from './shared/signup-modal/signup-modal.component';
import { ResidorgLoginFormComponent } from './shared/residorg-login-form/residorg-login-form.component';
import { MarkerModule } from '@colsen1991/ngx-translate-extract-marker/extras';
import { NewPasswordComponent } from './shared/new-password/new-password.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    LoginFormComponent,
    ControlErrorsComponent,
    ForgotPswModalComponent,
    SignupModalComponent,
    ResidorgLoginFormComponent,
    NewPasswordComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AuthApiModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      titleClass: 'toast-title',
      messageClass: 'toast-message'
    }),
    TranslateModule.forRoot(),
    MarkerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [TranslateService]
    },
    {
      provide: AUTH_BASE_PATH,
      useValue: environment.basePath
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
