/**
 * Comelit Cloud Services Authentication
 * Comelit Cloud Services Authentication
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RequestBodyCcapiEndpoint {
  /**
   * Only possible value: 1.0.0
   */
  version: RequestBodyCcapiEndpoint.VersionEnum;
  /**
   * Only possible value: eps/rtk
   */
  service: RequestBodyCcapiEndpoint.ServiceEnum;
  /**
   * Request identifier chosen by the client and reported by the server in the response
   */
  requuid: string;
}
export namespace RequestBodyCcapiEndpoint {
  export type VersionEnum = "1.0.0";
  export const VersionEnum = {
    _100: "1.0.0" as VersionEnum,
  };
  export type ServiceEnum = "eps/rtk";
  export const ServiceEnum = {
    EpsRtk: "eps/rtk" as ServiceEnum,
  };
}
