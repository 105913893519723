<div class="card-body">
  <p *ngIf="client === 'teletek'">{{ 'SIGNIN.CAPTION' | translate }}</p>
  <ng-container *ngIf="client !== 'teletek'">
    <img [src]="image" alt="Comelit Pro" title="Comelit Pro" class="logo" />
    <h1 id="main-title">
      {{
        isIngenium
          ? ('SIGNIN.DEVICE_CUSTOMIZATION' | translate)
          : ('SIGNIN.INSTALLATION_MANAGEMENT' | translate)
      }}
    </h1>
  </ng-container>
  <h2 class="primary-color">{{ 'SIGNIN.TITLE' | translate }}</h2>
  <div class="modal-message"></div>
  <form class="form-default" [formGroup]="loginGroup">
    <fieldset>
      <div class="form-group">
        <label>
          {{ 'SIGNIN.EMAIL' | translate }}
          <sup>*</sup></label
        >
        <input
          id="email"
          name="email"
          [placeholder]="'SIGNIN.EMAIL' | translate"
          formControlName="email"
          type="email"
          class="custom-input"
        />
        <app-control-errors
          id="email-validator"
          controlName="email"
          [customMessages]="{
            pattern: 'VALIDATE.EMAIL_NOT_CORRECT' | translate
          }"
        ></app-control-errors>
      </div>
      <div class="form-group">
        <label class="">
          {{ 'SIGNIN.PASSWORD' | translate }}
          <sup>*</sup></label
        >
        <input
          id="password"
          name="password"
          [placeholder]="'SIGNIN.PASSWORD' | translate"
          formControlName="password"
          type="password"
          class="custom-input"
        />
        <app-control-errors
          id="password-validator"
          controlName="password"
        ></app-control-errors>
      </div>
    </fieldset>
    <div class="text-center">
      <button
        id="submit"
        (click)="login()"
        type="submit"
        class="button-primary px-5 pt-2 pb-1 mt-3"
      >
        {{ 'SIGNIN.LOGIN' | translate }}
      </button>
    </div>
  </form>
  <div class="mt-3">
    <ng-container *ngIf="!showPopups">
      <p class="text-primary d-block pt-3 mb-1">
        {{ 'SIGNIN.FORGOT_PASSWORD_OR_NOACCOUNT' | translate }}
      </p>
      <p class="text-secondary" [innerHTML]="goToLink"></p>
    </ng-container>
    <p class="text-primary d-block pt-3 mb-3" *ngIf="showPopups">
      <a
        id="retrieve-psw"
        class="link-primary"
        href="javascript:void(0)"
        (click)="openRetrievePswModal()"
        >{{ 'SIGNIN.FORGOT_PASSWORD' | translate }}</a
      >
      <br />
      <a
        id="register"
        class="link-primary"
        href="javascript:void(0)"
        (click)="openRegisterModal()"
        >{{ 'SIGNIN.REGISTER_ACCOUNT' | translate }}</a
      >
    </p>
    <img
      *ngIf="client === 'teletek'"
      [src]="image"
      alt="Teletek"
      title="Teletek"
      class="logo"
    />
  </div>
</div>
