import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { mustMatch } from '../must-match.validator';
import { checkFormValidity } from 'src/app/utils';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
interface ChangePswForm {
  psw: FormControl<string>;
  pswConfirm: FormControl<string>;
}

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  token: string | null;
  callback: string | null;
  newPswGroup!: FormGroup;
  pswControl = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.pattern(
        '(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,-./:;<=>?@[\\]^_{|}~]).{8,}'
      )
    ]
  });
  confirmPswControl = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.pattern(
        '(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,-./:;<=>?@[\\]^_{|}~]).{8,}'
      )
    ]
  });
  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((r) => {
      this.token = r.token;
      this.callback = r.callback || r.redirect_uri;
    });

    this.newPswGroup = new FormGroup(
      {
        psw: this.pswControl,
        pswConfirm: this.confirmPswControl
      },
      mustMatch('psw', 'pswConfirm')
    );
  }

  goToLogin() {
    this.router.navigate(['/'], {
      queryParams: {
        callback: this.callback
      }
    });
  }

  changePsw() {
    if (checkFormValidity(this.newPswGroup) && this.token) {
      let data = {
        resetPasswordToken: this.token,
        newPassword: this.pswControl.value
      };
      this.apiService.changePsw(data).subscribe(
        (data: any) => {
          this.toastr.success(
            this.ts.instant('ERRORS.HTTP_ERROR'),
            this.ts.instant('NEW_PSW.CHANGE_PSW_SUCCESS')
          );
        },
        (error) => {
          this.toastr.error(
            this.ts.instant('ERRORS.HTTP_ERROR'),
            this.ts.instant('ERRORS.CHANGE_PSW_ERROR')
          );
        }
      );
    }
  }
}
