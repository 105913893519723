<app-header></app-header>
<main>
  <div class="container mt-5 cloud-login">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
<app-footer *ngIf="client !== 'residorg'"></app-footer>
