import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as countries from 'i18n-iso-countries';
import localeDataEn from 'i18n-iso-countries/langs/en.json';
import localeDataIt from 'i18n-iso-countries/langs/it.json';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserPortalService } from 'src/app/api/user';
import { getUserLanguage } from 'src/app/app.init';
import { checkFormValidity, checkOpenParam, getCallback } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { mustMatch } from '../must-match.validator';
interface SignupForm {
  loginEmail: FormControl<string>;
  password: FormControl<string>;
  passwordConfirm: FormControl<string>;
  userType: FormControl<string>;
  isoCountryCode: FormControl<string>;
  commercial: FormControl<boolean>;
  privacy: FormControl<boolean>;
  country?: FormControl<string>;
}

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit {
  signupGroup: FormGroup<SignupForm>;
  emailControl = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ]
  });
  passwordControl = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.pattern(
        '(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,-./:;<=>?@[\\]^_{|}~]).{8,}'
      )
    ]
  });

  passwordConfirmControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required]
  });
  userTypeControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required]
  });
  coutryControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required]
  });
  commercialControl = new FormControl(false, {
    nonNullable: true,
    validators: [Validators.required]
  });
  privacyControl = new FormControl(false, {
    nonNullable: true,
    validators: [Validators.requiredTrue]
  });
  countryList: any[];

  public client = environment.client;

  roles = [
    {
      value: 'INSTALLER',
      label: this.ts.instant('ROLES.INSTALLER')
    },
    {
      value: 'BUILDING_MANAGER',
      label: this.ts.instant('ROLES.BUILDING_MANAGER')
    }
  ];

  constructor(
    public modalRef: BsModalRef,
    private ts: TranslateService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private userService: UserPortalService
  ) {}

  ngOnInit(): void {
    if (this.client === 'comelit') {
      this.roles.push(
        {
          value: 'END_USER',
          label: this.ts.instant('ROLES.END_USER')
        },
        {
          value: 'DISTRIBUTOR',
          label: this.ts.instant('ROLES.DISTRIBUTOR')
        },
        {
          value: 'DESIGNER',
          label: this.ts.instant('ROLES.DESIGNER')
        },
        {
          value: 'EXCELLENCE_PARTNER',
          label: this.ts.instant('ROLES.EXCELLENCE_PARTNER')
        },
        {
          value: 'ARCHITECT',
          label: this.ts.instant('ROLES.ARCHITECT')
        },
        {
          value: 'SYSTEM_INTEGRATOR',
          label: this.ts.instant('ROLES.SYSTEM_INTEGRATOR')
        }
      );
    }
    countries.registerLocale(
      getUserLanguage() === 'it' ? localeDataIt : localeDataEn
    );
    const countryList = countries.getNames(
      getUserLanguage() === 'it' ? 'it' : 'en'
    );

    this.countryList = [];
    for (const key in countryList) {
      if (Object.prototype.hasOwnProperty.call(countryList, key)) {
        const element = countryList[key];
        this.countryList.push({
          value: key,
          label: element
        });
      }
    }

    this.signupGroup = new FormGroup<SignupForm>(
      {
        loginEmail: this.emailControl,
        password: this.passwordControl,
        passwordConfirm: this.passwordConfirmControl,
        userType: this.userTypeControl,
        isoCountryCode: this.coutryControl,
        commercial: this.commercialControl,
        privacy: this.privacyControl
      },
      mustMatch('password', 'passwordConfirm')
    );
  }

  signup() {
    if (checkFormValidity(this.signupGroup)) {
      const registerData = this.signupGroup.value;
      delete registerData.privacy;
      registerData.country = this.countryList.find(
        (x) => x.value === this.coutryControl.value
      ).label;

      this.apiService
        .register({
          provider: 'NATIVE',
          providerData: {
            email: this.emailControl.value,
            password: this.passwordControl.value
          },
          userProfile: {
            isoCountryCode: this.coutryControl.value,
            receiveNewsletter: this.commercialControl.value,
            userType: this.userTypeControl.value
          }
        })
        .subscribe(
          (data: any) => {
            if (data.ccapi.error.code !== 0) {
              switch (data.ccapi.error.code) {
                case 9001:
                  this.toastr.error(
                    this.ts.instant('ERRORS.USER_ALREADY_REGISTERED'),
                    this.ts.instant('ERRORS.HTTP_ERROR')
                  );
                  break;
                default:
                  this.toastr.error(
                    this.ts.instant('ERRORS.ERROR_SIGNIN'),
                    this.ts.instant('ERRORS.HTTP_ERROR')
                  );
                  break;
              }
            } else {
              this.modalRef.hide();
              this.toastr.success(
                this.ts.instant('SIGNIN.SUCCESS_SIGNIN'),
                this.ts.instant('SIGNIN.SUCCESS')
              );

              if (checkOpenParam() !== null && getCallback() !== null) {
                window.location.href = getCallback()!;
              }
            }
          },
          (error) => {}
        );
    }
  }
}
