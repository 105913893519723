import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { getUserLanguage } from 'src/app/app.init';
import { isHca } from 'src/app/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  languages: any[] = [];
  languageControl = new FormControl<string>('');
  menuOpen = false;
  image: SafeStyle;
  client = environment.client;
  constructor(private ts: TranslateService) {}

  ngOnInit(): void {
    this.image = isHca()
      ? `assets/images/comelit-pac-logo.svg`
      : `assets/images/${environment.client}-logo.svg`;

    this.languageSet();
  }

  getAlt(client: string) {
    let alt = '';
    switch (client) {
      case 'teletek':
        alt = 'Teletek Logo';
        break;
      case 'residorg':
        alt = 'Comelit Residorg Logo';
        break;
      case 'comelit':
      default:
        alt = 'Comelit Logo';
        break;
    }

    return alt;
  }

  languageSet() {
    this.languages = [
      {
        name: this.ts.instant('LANGUAGES.US'),
        image: 'assets/images/us.svg',
        value: 'us'
      },
      {
        name: this.ts.instant('LANGUAGES.IT'),
        image: 'assets/images/it.svg',
        value: 'it'
      },
      {
        name: this.ts.instant('LANGUAGES.EN'),
        image: 'assets/images/en.svg',
        value: 'en'
      },
      {
        name: this.ts.instant('LANGUAGES.DE'),
        image: 'assets/images/de.svg',
        value: 'de'
      },
      {
        name: this.ts.instant('LANGUAGES.ES'),
        image: 'assets/images/es.svg',
        value: 'es'
      },
      {
        name: this.ts.instant('LANGUAGES.FR'),
        image: 'assets/images/fr.svg',
        value: 'fr'
      },
      {
        name: this.ts.instant('LANGUAGES.NL'),
        image: 'assets/images/nl.svg',
        value: 'nl'
      },
      {
        name: this.ts.instant('LANGUAGES.SA'),
        image: 'assets/images/sa.svg',
        value: 'sa'
      },
      {
        name: this.ts.instant('LANGUAGES.SI'),
        image: 'assets/images/si.svg',
        value: 'si'
      },
      {
        name: this.ts.instant('LANGUAGES.IR'),
        image: 'assets/images/ir.svg',
        value: 'ir'
      },
      {
        name: this.ts.instant('LANGUAGES.GR'),
        image: 'assets/images/gr.svg',
        value: 'gr'
      },
      {
        name: this.ts.instant('LANGUAGES.RU'),
        image: 'assets/images/ru.svg',
        value: 'ru'
      },
      {
        name: this.ts.instant('LANGUAGES.BG_NL'),
        image: 'assets/images/bg.svg',
        value: 'be-nl'
      },
      {
        name: this.ts.instant('LANGUAGES.BG_FR'),
        image: 'assets/images/bg.svg',
        value: 'be-fr'
      },
      {
        name: this.ts.instant('LANGUAGES.PT'),
        image: 'assets/images/pt.svg',
        value: 'pt'
      }
    ];
    this.languageControl.setValue(
      getUserLanguage() !== '' ? getUserLanguage() : 'en'
    );
    this.languageControl.valueChanges.subscribe((v) => {
      localStorage.setItem('language', v || '');

      location.replace(location.href);
      location.reload();
    });
  }
}
