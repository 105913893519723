import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  callback,
  checkFormValidity,
  checkOpenParam,
  getCallback,
  isHca
} from 'src/app/utils';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-psw-modal',
  templateUrl: './forgot-psw-modal.component.html',
  styleUrls: ['./forgot-psw-modal.component.scss']
})
export class ForgotPswModalComponent implements OnInit {
  pswGroup: FormGroup<{ email: FormControl<string> }>;
  emailControl = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ]
  });
  constructor(
    private modalRef: BsModalRef,
    private apiService: ApiService,
    private ts: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.pswGroup = new FormGroup<{ email: FormControl<string> }>({
      email: this.emailControl
    });
  }

  resetPsw() {
    if (checkFormValidity(this.pswGroup)) {
      this.apiService
        .resetPsw({
          ...this.pswGroup.value,
          callbackUrl:
            window.location.origin +
            (isHca() ? '/login-hca/#' : '/servicerest/login/#') +
            '/new-password?callback=' +
            (callback || environment.sitePath),
          template: isHca() ? 'HOUSING_CENTRAL' : null
        })
        .subscribe((data: any) => {
          if (data.ccapi.error.code !== 0) {
            this.toastr.error(
              this.ts.instant('ERRORS.ERROR_RESET_PSW'),
              this.ts.instant('ERRORS.HTTP_ERROR')
            );
          } else {
            this.modalRef.hide();
            this.toastr.success(
              this.ts.instant('PSW.SUCCESS_RESET_PSW'),
              this.ts.instant('SIGNIN.SUCCESS')
            );

            if (checkOpenParam() !== null && getCallback() !== null) {
              window.location.href = getCallback()!;
            }
          }
        });
    }
  }
}
