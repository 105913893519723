/**
 * MyComelit portal user API
 * API to interact with user from MyComelit portal
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SetPropertyResultDto } from './setPropertyResultDto';

export interface SetMyProfileResponseDto {
  isoCountryCode?: SetPropertyResultDto;
  isoLanguageCode?: SetPropertyResultDto;
  address?: SetPropertyResultDto;
  businessName?: SetPropertyResultDto;
  cellnum?: SetPropertyResultDto;
  city?: SetPropertyResultDto;
  company?: SetPropertyResultDto;
  country?: SetPropertyResultDto;
  dateOfBirth?: SetPropertyResultDto;
  distributor?: SetPropertyResultDto;
  ecommerceUserId?: SetPropertyResultDto;
  contactEmail?: SetPropertyResultDto;
  firstName?: SetPropertyResultDto;
  lastName?: SetPropertyResultDto;
  language?: SetPropertyResultDto;
  profilePictureBase64?: SetPropertyResultDto;
  region?: SetPropertyResultDto;
  state?: SetPropertyResultDto;
  streetNumber?: SetPropertyResultDto;
  vatNumber?: SetPropertyResultDto;
  zipCode?: SetPropertyResultDto;
  phoneNumber?: SetPropertyResultDto;
  userType?: SetPropertyResultDto;
  crmUserId?: SetPropertyResultDto;
  receiveNewsletter?: SetPropertyResultDto;
  preferredContactMethod?: SetPropertyResultDto;
  navigationLanguage?: SetPropertyResultDto;
}
