import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { isHca } from 'src/app/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  moreInfo: SafeStyle;
  client = environment.client;
  isHca = isHca();
  constructor(private ts: TranslateService, private sanitized: DomSanitizer) {}

  ngOnInit(): void {
    this.moreInfo = this.sanitized.bypassSecurityTrustHtml(
      this.ts.instant('FOOTER.MORE_INFO')
    );
  }
}
