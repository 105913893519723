/**
 * Comelit Cloud Services Authentication
 * Comelit Cloud Services Authentication
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestBodyCcapiEndpoint } from "./requestBodyCcapiEndpoint";
import { ResponseBodyCcapiError } from "./responseBodyCcapiError";
import { RequestBodyCcapiLogin } from "./requestBodyCcapiLogin";
import { ResponseBodyCcapiBody } from "./responseBodyCcapiBody";

export interface ResponseBodyCcapi {
  /**
   * Only possible value: 1.1.0
   */
  version: ResponseBodyCcapi.VersionEnum;
  endpoint: RequestBodyCcapiEndpoint;
  login: RequestBodyCcapiLogin;
  error: ResponseBodyCcapiError;
  body: ResponseBodyCcapiBody;
}
export namespace ResponseBodyCcapi {
  export type VersionEnum = "1.1.0";
  export const VersionEnum = {
    _110: "1.1.0" as VersionEnum,
  };
}
