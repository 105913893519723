<div class="card-body pt-5 px-3">
  <h2 class="primary-color">{{ 'NEW_PSW.TITLE' | translate }}</h2>
  <div class="modal-message"></div>
  <form class="form-default" [formGroup]="newPswGroup">
    <fieldset>
      <div class="form-group">
        <label>
          {{ 'NEW_PSW.PSW' | translate }}
          <sup>*</sup></label
        >
        <input
          id="psw"
          name="psw"
          [placeholder]="'NEW_PSW.PSW' | translate"
          formControlName="psw"
          type="password"
          class="custom-input"
        />
        <app-control-errors
          id="psw-validator"
          controlName="psw"
          [customMessages]="{
            pattern: 'VALIDATE.PASSWORD_PATTERN' | translate,
            required: 'VALIDATE.PASSWORD_REQUIRED' | translate,
            mustMatch: 'VALIDATE.PASSWORDS_NOT_EQUAL' | translate
          }"
        ></app-control-errors>
      </div>
      <div class="form-group">
        <label class="">
          {{ 'NEW_PSW.PSW_CONFIRM' | translate }}
          <sup>*</sup></label
        >
        <input
          id="psw-confirm"
          name="psw-confirm"
          [placeholder]="'NEW_PSW.PSW_CONFIRM' | translate"
          formControlName="pswConfirm"
          type="password"
          class="custom-input"
        />
        <app-control-errors
          id="psw-confirm-validator"
          controlName="pswConfirm"
          [customMessages]="{
            required: 'VALIDATE.PASSWORD_REQUIRED' | translate,
            mustMatch: 'VALIDATE.PASSWORDS_NOT_EQUAL' | translate
          }"
        ></app-control-errors>
      </div>
    </fieldset>

    <div class="text-center">
      <button
        id="submit"
        (click)="changePsw()"
        type="submit"
        class="button-primary px-5 pt-2 pb-1 mt-3"
      >
        {{ 'NEW_PSW.CHANGE_PASSWORD' | translate }}
      </button>
    </div>
  </form>
  <div class="mt-3">
    <p class="text-primary d-block pt-3">
      <a
        id="retrieve-psw"
        class="link-primary"
        href="javascript:void(0)"
        (click)="goToLogin()"
        >{{ 'NEW_PSW.GO_TO_LOGIN' | translate }}</a
      >
    </p>
  </div>
</div>
